import React, { Fragment, useEffect } from "react";
import { Presentation } from "./Components/Presentation";
import { RecursosChild1 } from "./Components/Resources/RecursosChild1";
import { RecursosChild2 } from "./Components/Resources/RecursosChild2";
import { Revista } from "./Components/Resources/Revista";
import useScript from "./UseScript";
import pdfPresentacionEn from "../src/Icons/IWS Presentacion ejecutiva 2023.pdf";
import pdfPresentacionEs from "../src/Icons/IWS Presentacion ejecutiva 2023.pdf";
import pdfPresentacionBr from "../src/Icons/Redinet_Presentação_Executiva2024.pdf";
import pdfEBook from "./Icons/Ebook_InteligenciaArtificial.pdf";
import { useTranslation } from "react-i18next";

import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const img1DesktopEs = "https://cdn.iwsservices.com/Secciones/Desktop/Es/Recursos.png";
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Resources.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Recursos.png";
const img1MobileEs = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Recursos.png";
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Resources.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Recursos.png";
const downloads = "https://cdn.iwsservices.com/Recursos/Descargas2.png";
const downloadsEn = "https://cdn.iwsservices.com/Recursos/Descargas2En.png";
const revista = "https://cdn.iwsservices.com/Recursos/Revista Tech.png";
const revistaEn = "https://cdn.iwsservices.com/Recursos/Revista Tech En.png";
const img05 = "https://cdn.iwsservices.com/Revista/magazine05.png";
const img06 = "https://cdn.iwsservices.com/Revista/magazine06.png";
const img07 = "https://cdn.iwsservices.com/Revista/magazine07.png";
const img08 = "https://cdn.iwsservices.com/Revista/magazine08.png";
const img09 = "https://cdn.iwsservices.com/Revista/magazine09.png";
const img10 = "https://cdn.iwsservices.com/Revista/magazine10.jpg";
const img11 = "https://cdn.iwsservices.com/Revista/magazine11.png";
const img12 = "https://cdn.iwsservices.com/Revista/magazine12.png";
const img13 = "https://cdn.iwsservices.com/Revista/magazine13.png";
const img14 = "https://cdn.iwsservices.com/Revista/magazine14.png";
const img15 = "https://cdn.iwsservices.com/Revista/magazine15.png";
const img16 = "https://cdn.iwsservices.com/Revista/magazine16.png";
const img17 = "https://cdn.iwsservices.com/Revista/magazine17.png";
const img18 = "https://cdn.iwsservices.com/Revista/magazine18.png";
const img19 = "https://cdn.iwsservices.com/Revista/magazine19.png";
const img20 = "https://cdn.iwsservices.com/Revista/magazine20.png";
const img21 = "https://cdn.iwsservices.com/Revista/magazine21.png";
const img22 = "https://cdn.iwsservices.com/Revista/magazine22.png";
const img23 = "https://cdn.iwsservices.com/Revista/magazine23.png";
const img24 = "https://cdn.iwsservices.com/Revista/magazine24.png";
const img25 = "https://cdn.iwsservices.com/Revista/magazine25.png";
const img26 = "https://cdn.iwsservices.com/Revista/magazine26.png";
const img27 = "https://cdn.iwsservices.com/Revista/magazine27.png";
const img28 = "https://cdn.iwsservices.com/Revista/magazine28.png";
const img29 = "https://cdn.iwsservices.com/Revista/magazine29.png";
const img30 = "https://cdn.iwsservices.com/Revista/magazine30.png";
const img31 = "https://cdn.iwsservices.com/Revista/magazine31.png";
const img32 = "https://cdn.iwsservices.com/Revista/magazine32.png";
const img33 = "https://cdn.iwsservices.com/Revista/magazine33.png";
const img34 = "https://cdn.iwsservices.com/Revista/magazine34.png";
const img35 = "https://cdn.iwsservices.com/Revista/magazine35.png";
const img36 = "https://cdn.iwsservices.com/Revista/magazine36.png";
const img37 = "https://cdn.iwsservices.com/Revista/magazine37.png";
const img38 = "https://cdn.iwsservices.com/Revista/magazine38.png";
const img39 = "https://cdn.iwsservices.com/Revista/magazine39.png";
const img40 = "https://cdn.iwsservices.com/Revista/magazine40.png";
const img41 = "https://cdn.iwsservices.com/Revista/magazine41.png";
const img42 = "https://cdn.iwsservices.com/Revista/magazine42.png";
const img43 = "https://cdn.iwsservices.com/Revista/magazine43.png";
const img44 = "https://cdn.iwsservices.com/Revista/magazine44.png";
const img45 = "https://cdn.iwsservices.com/Revista/magazine45.png";
const img46 = "https://cdn.iwsservices.com/Revista/magazine46.png";
const img47 = "https://cdn.iwsservices.com/Revista/magazine47.png";
const img48 = "https://cdn.iwsservices.com/Revista/magazine48.png";
const img49 = "https://cdn.iwsservices.com/Revista/magazine49.png";

export function Recursos() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation("common");
  const domainName = window.location.hostname;

  const component1 = useScript("all.js", "");
  const component2 = useScript("slick.js", "");
  const component3 = useScript("hash.js", "");
  const component4 = useScript("turn.min.js", "");
  const component5 = useScript("main.js", "");
  const images = [
    {
      class: "sample thumb10",
      sample: "magazine49",
      src: img49,
      id: "RevistaTechNoviembre2024",
      alt: "magazine49",
      Text1: `${t("revista.nov24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine48",
      src: img48,
      id: "RevistaTechOctubre2024",
      alt: "magazine48",
      Text1: `${t("revista.oct24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine47",
      src: img47,
      id: "RevistaTechSeptiembre2024",
      alt: "magazine47",
      Text1: `${t("revista.sep24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine46",
      src: img46,
      id: "RevistaTechAgosto2024",
      alt: "magazine46",
      Text1: `${t("revista.agos24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine45",
      src: img45,
      id: "RevistaTechJulio2024",
      alt: "magazine44",
      Text1: `${t("revista.jul24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine44",
      src: img44,
      id: "RevistaTechJunio2024",
      alt: "magazine44",
      Text1: `${t("revista.jun24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine43",
      src: img43,
      id: "RevistaTechMayo2024",
      alt: "magazine43",
      Text1: `${t("revista.may24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine42",
      src: img42,
      id: "RevistaTechAbril2024",
      alt: "magazine41",
      Text1: `${t("revista.abr24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine41",
      src: img41,
      id: "RevistaTechMarzo2024",
      alt: "magazine41",
      Text1: `${t("revista.mar24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine40",
      src: img40,
      id: "RevistaTechFebrero2024",
      alt: "magazine40",
      Text1: `${t("revista.feb24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine39",
      src: img39,
      id: "RevistaTechEnero2024",
      alt: "magazine39",
      Text1: `${t("revista.ene24")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine38",
      src: img38,
      id: "RevistaTechDiciembre2023",
      alt: "magazine38",
      Text1: `${t("revista.dic23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine37",
      src: img37,
      id: "RevistaTechNoviembre2023",
      alt: "magazine37",
      Text1: `${t("revista.nov23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine36",
      src: img36,
      id: "RevistaTechOctubre2023",
      alt: "magazine36",
      Text1: `${t("revista.oct23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine35",
      src: img35,
      id: "RevistaTechSeptiembre2023",
      alt: "magazine35",
      Text1: `${t("revista.sep23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine34",
      src: img34,
      id: "RevistaTechAgosto2023",
      alt: "magazine34",
      Text1: `${t("revista.agos23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine33",
      src: img33,
      id: "RevistaTechJulio2023",
      alt: "magazine33",
      Text1: `${t("revista.jul23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine32",
      src: img32,
      id: "RevistaTechJunio2023",
      alt: "magazine32",
      Text1: `${t("revista.jun23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine31",
      src: img31,
      id: "RevistaTechMayo2023",
      alt: "magazine31",
      Text1: `${t("revista.may23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine30",
      src: img30,
      id: "RevistaTechAbril2023",
      alt: "magazine30",
      Text1: `${t("revista.abr23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine29",
      src: img29,
      id: "RevistaTechMarzo2023",
      alt: "magazine29",
      Text1: `${t("revista.mar23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine28",
      src: img28,
      id: "RevistaTechFebrero2023",
      alt: "magazine28",
      Text1: `${t("revista.feb23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine27",
      src: img27,
      id: "RevistaTechEnero2023",
      alt: "magazine27",
      Text1: `${t("revista.ene23")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine26",
      src: img26,
      id: "RevistaTechDiciembre2022",
      alt: "magazine26",
      Text1: `${t("revista.dic22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine25",
      src: img25,
      id: "RevistaTechNoviembre2022",
      alt: "magazine25",
      Text1: `${t("revista.nov22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine24",
      src: img24,
      id: "RevistaTechOctubre2022",
      alt: "magazine24",
      Text1: `${t("revista.oct22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine23",
      src: img23,
      id: "RevistaTechSeptiembre2022",
      alt: "magazine23",
      Text1: `${t("revista.sep22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine22",
      src: img22,
      id: "RevistaTechAgosto2022",
      alt: "magazine22",
      Text1: `${t("revista.agos22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine21",
      src: img21,
      id: "RevistaTechJulio2022",
      alt: "magazine21",
      Text1: `${t("revista.jul22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine20",
      src: img20,
      id: "RevistaTechJunio2022",
      alt: "magazine20",
      Text1: `${t("revista.jun22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine19",
      src: img19,
      id: "RevistaTechMayo2022",
      alt: "magazine19",
      Text1: `${t("revista.may22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine18",
      src: img18,
      id: "RevistaTechAbril2022",
      alt: "magazine18",
      Text1: `${t("revista.abr22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine17",
      src: img17,
      id: "RevistaTechMarzo2022",
      alt: "magazine17",
      Text1: `${t("revista.mar22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine16",
      src: img16,
      id: "RevistaTechFebrero2022",
      alt: "magazine16",
      Text1: `${t("revista.feb22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine15",
      src: img15,
      id: "RevistaTechEnero2022",
      alt: "magazine15",
      Text1: `${t("revista.ene22")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine14",
      src: img14,
      id: "RevistaTechDiciembre2021",
      alt: "magazine14",
      Text1: `${t("revista.dic21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine13",
      src: img13,
      id: "RevistaTechNoviembre2021",
      alt: "magazine13",
      Text1: `${t("revista.nov21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine12",
      src: img12,
      id: "RevistaTechOctubre2021",
      alt: "magazine12",
      Text1: `${t("revista.oct21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine11",
      src: img11,
      id: "RevistaTechSeptiembre2021",
      alt: "magazine11",
      Text1: `${t("revista.sep21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine10",
      src: img10,
      id: "RevistaTechAgosto2021",
      alt: "magazine10",
      Text1: `${t("revista.agos21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine9",
      src: img09,
      id: "RevistaTechJulio2021",
      alt: "magazine09",
      Text1: `${t("revista.jul21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine8",
      src: img08,
      id: "RevistaTechJunio2021",
      alt: "magazine08",
      Text1: `${t("revista.jun21")}`,
    },
    {
      class: "sample thumb10",
      sample: "magazine7",
      src: img07,
      id: "RevistaTechMayo2021",
      alt: "magazine07",
      Text1: `${t("revista.may21")}`,
    },
    {
      class: "sample thumb9",
      sample: "magazine6",
      src: img06,
      id: "RevistaTechAbril2021",
      alt: "magazine06",
      Text1: `${t("revista.abr21")}`,
    },
    {
      class: "sample thumb8",
      sample: "magazine5",
      src: img05,
      id: "RevistaTechMarzo2021",
      alt: "magazine05",
      Text1: `${t("revista.mar21")}`,
    },
  ];
  return (
    <div>
      <Header />

      <div>
        <Presentation
          image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
          image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
          text={t("recursos.text")}
        />
        <div className="pleca-2"></div>
      </div>

      <RecursosChild1 />

      <div>
        <div
          id="Revista"
          className="row m-0 p-0 position-relative mt-0 mt-sm-5 justify-content-center"
        >
          <div className="gris-derecha"></div>

          <div className="m-0 p-0 my-5">
            <Revista image1={revista} image2={revistaEn} images={images} />
          </div>

          <div className="mt-5 text-center text-unnamed col-12 col-sm-12 col-md-8 col-lg-6 col-xl-5 col-xxl-5">
            <p className="title-Contact m-0 p-0">{t("recursos.mes")}</p>
            <p className="text-27-light">{t("recursos.novedades")}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="linea-recu"></div>
        </div>
        <div className="text-center info-Us info-Us2 text-masInfo2">
          <p className="text-azul p-Us text-masInfo">
            {t("recursos.descargalas")}
          </p>
          {t("recursos.informacion")}
        </div>
        <div id="RecursosChild2" className="position-relative">
          <RecursosChild2 image1={downloadsEn} image2={downloads} />
          <div className="row divIcon-dow">
            <div className="col-3 iconDow1">
              {
                <a href={domainName.includes('iwsservices.com.br') ? pdfPresentacionBr : (domainName.includes('redinet.us')) || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? pdfPresentacionEn : pdfPresentacionEs } download>
                  <img  className="img-des" />
                </a>
              }
            </div>

            <div className="col-3 iconDow2">
              <a href={pdfEBook} download>
                <img  className="img-des" />
              </a>
            </div>
          </div>
        </div>
        {component1}
        {component2}
        {component3}
        {component4}
        {component5}

      </div>

      <ToggleComp />
      <Footer />
    </div>
  );
}
